import React, { Component } from "react"
import { graphql } from "gatsby"

import { Layout, Seo, Preview, Sidebar } from "../components"

class Category extends Component {
  render() {
    const data = this.props.data
    const catName = this.props.pageContext.name

    return (
      <Layout>
        <Seo title={catName} />
        <section className="section">
          <div className="container">
            <div className="columns ">
              <div className="column has-background-white is-two-thirds">
                <h1 className="title is-1">{catName}</h1>
                <div className="columns is-multiline">
                  {data.allWordpressPost.edges.map(({ node }) => (
                    <Preview post={node} key={node.slug} />
                  ))}
                </div>
              </div>
              <div className="has-background-grey-lighter column">
                    <Sidebar />
              </div>
            </div>
          </div>
        </section>
      </Layout>
    )
  }
}

export default Category

export const pageQuery = graphql`
  query CatQuery($id: String!) {
    allWordpressPost(filter: {categories: {elemMatch: {id: {eq: $id}}}}) {
      edges {
        node {
          title
          excerpt
          slug
          date
          featured_media {
            localFile {
              childImageSharp {
                fluid(maxHeight: 300, maxWidth: 600) {
                  ...GatsbyImageSharpFluid_withWebp_tracedSVG
                }
              }
            }
          }
        }
      }
    }
  }
`